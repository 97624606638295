import { createClient } from "@sanity/client";
import imageUrlBuilder from '@sanity/image-url';

// Initialize the client with `createClient` and specify an API version
const client = createClient({
  projectId: "1dmsbh4j",
  dataset: "production",
  useCdn: true,
  apiVersion: '2021-03-25' // Use the date of the API version you want to target
});

export default client;

export const urlFor = (source) => {
  return imageUrlBuilder(client).image(source)
}
