import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { IoLockOpen } from "react-icons/io5";
import FormWithWebhook from "./FormWithWebhook";

const ContactUs = ({ onSubmit }) => {
  const [showForm, setShowForm] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const handleClick = () => {
    if (!submissionSuccess) {
      setShowForm(!showForm);
    }
  };

  const handleFormSubmit = () => {
    // This function will be called when the form is submitted
    setSubmissionSuccess(true);
    setShowForm(false);
  };

  return (
    <div className='ContactUs'>
      {showForm ? (
        <FormWithWebhook onSubmit={onSubmit} onFormSubmit={handleFormSubmit} />
      ) : (
        <Button 
          type="button" 
          color="primary" 
          onClick={handleClick} 
          disabled={submissionSuccess} 
          style={{marginBottom:"3em", marginTop:"3em", width:"69vw"}}
        >
          {submissionSuccess ? "Submission Successful" : <><IoLockOpen  /> Unlock Exclusive Access</>}
        </Button>
      )}
    </div>
  );
};

export default ContactUs;
