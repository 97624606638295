import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  return (
    <Particles
      id="tsparticles"
      options={{
        particles: {
          number: {
            value: 250, // Number of particles
            density: {
              enable: true,
              value_area: 1500, // Area in which the particles will be distributed
            },
          },
          lineLinked: {
            enable: false, // Whether to show lines between particles
          },
          move: {
            enable: true, // Whether particles can move
            speed: 0.15, // Speed at which particles move
            direction: "none",
            random: true,
            straight: false,
            outModes: {
              default: "bounce", // How particles will behave when they move out of bounds
            },
          },
          size: {
            value: 1, // Size of the particles
          },
          opacity: {
            animation: {
              enable: true,
              speed: 1, // Speed at which opacity animation occurs
              minimumValue: 0.01, // Minimum opacity value
            },
          },
        },
        interactivity: {
          detectsOn: "window", // Element on which interactivity events are detected
          events: {
            onClick: {
              enable: true, // Whether particles react to click events
              mode: "push", // Interaction mode on click
            },
            onHover: {
              enable: true, // Whether particles react to hover events
              mode: "repulse", // Interaction mode on hover (Other options: grab, bubble)
            },
          },
          modes: {
            push: {
              particles_nb: 25,

            },
            repulse: {
              distance: 80, // Distance particles are repulsed (Min: 1, Max: 1000)
              duration: 5, // Duration of the repulsion effect (Min: 0.1, Max: 5)
            },
          },
        },
        detectRetina: true, // Whether to detect and support retina displays
      }}
    />
  );
}

export default Particle;
// import React from "react";
// import Particles from "@tsparticles/react";

// function Particle() {
//   return (
//     <Particles
//       id="tsparticles"
//       options={{
//         particles: {
//           number: {
//             value: 200,
//             density: {
//               enable: true,
//               value_area: 800
//             }
//           },
//           color: {
//             value: "#ffffff"
//           },
//           shape: {
//             type: "circle"
//           },
//           opacity: {
//             value: 0.5,
//             random: false
//           },
//           size: {
//             value: 3,
//             random: true
//           },
//           line_linked: {
//             enable: true,
//             distance: 150,
//             color: "#ffffff",
//             opacity: 0.4,
//             width: 1
//           },
//           move: {
//             enable: true,
//             speed: 6
//           }
//         }
//       }}
//     />
//   );
// }

// export default Particle;
