import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import Home2 from "./Home2";
import Particle from "../Particle";

function Home() {
  return (
    <section>
      <Particle />
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <div style={{ paddingBottom: 15 }}>
                <h1 className="heading-name">
                  Redefine Customer Engagement with <br></br>
                  <strong className="main-name"> LionAi</strong>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="wave-container waveSVG"></div>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
