import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from 'axios';
import PropTypes from 'prop-types';

const FormWithWebhook = ({ onFormSubmit }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "+94",
    businessName: "",
    industry: "",
    businessContact: "",
    message: ""
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName.trim()) {
      errors.firstName = "First name is required";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "Last name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\+\d{1,3}\d{9,15}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number";
    }
    if (!formData.businessName.trim()) {
      errors.businessName = "Business name is required";
    }
    if (!formData.industry.trim()) {
      errors.industry = "Industry is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post('https://hook.eu2.make.com/b4w845kg3mbnppvs4wvizof1r2d8krxx', formData);
        console.log('Webhook response:', response.data);
        onFormSubmit();
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "+94",
          businessName: "",
          industry: "",
          businessContact: "",
          message: ""
        });
      } catch (error) {
        console.error('Error sending data to webhook:', error);
      }
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formFirstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your first name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
        {errors.firstName && <Alert variant="danger">{errors.firstName}</Alert>}
      </Form.Group>

      <Form.Group controlId="formLastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your last name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
        {errors.lastName && <Alert variant="danger">{errors.lastName}</Alert>}
      </Form.Group>

      <Form.Group controlId="formEmail">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter your email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <Alert variant="danger">{errors.email}</Alert>}
      </Form.Group>

      <Form.Group controlId="formPhoneNumber">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="tel"
          placeholder="Enter your phone number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        {errors.phoneNumber && <Alert variant="danger">{errors.phoneNumber}</Alert>}
      </Form.Group>

      <Form.Group controlId="formBusinessName">
        <Form.Label>Business Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your business name"
          name="businessName"
          value={formData.businessName}
          onChange={handleChange}
        />
        {errors.businessName && <Alert variant="danger">{errors.businessName}</Alert>}
      </Form.Group>

      <Form.Group controlId="formIndustry">
        <Form.Label>Industry</Form.Label>
        <Form.Control
          as="select"
          name="industry"
          value={formData.industry}
          onChange={handleChange}
        >
          <option value="">Select a category</option>
          <option value="Agriculture">Agriculture</option>
          <option value="Apparel">Apparel</option>
          <option value="Automotive">Automotive</option>
          <option value="Beauty & Personal Care">Beauty & Personal Care</option>
          <option value="Construction">Construction</option>
          <option value="Education">Education</option>
          <option value="Electronics">Electronics</option>
          <option value="Entertainment">Entertainment</option>
          <option value="Financial Services">Financial Services</option>
          <option value="Food & Beverage">Food & Beverage</option>
          <option value="Health & Wellness">Health & Wellness</option>
          <option value="Home & Garden">Home & Garden</option>
          <option value="Hospitality">Hospitality</option>
          <option value="Information Technology">Information Technology</option>
          <option value="Legal Services">Legal Services</option>
          <option value="Manufacturing">Manufacturing</option>
          <option value="Marketing & Advertising">Marketing & Advertising</option>
          <option value="Media & Communication">Media & Communication</option>
          <option value="Real Estate">Real Estate</option>
          <option value="Retail">Retail</option>
          <option value="Transportation">Transportation</option>
        </Form.Control>
        {errors.industry && <Alert variant="danger">{errors.industry}</Alert>}
      </Form.Group>

      <Form.Group controlId="formBusinessContact">
        <Form.Label>Business Social Media or Website</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your business's social media handle or website (if available)"
          name="businessContact"
          value={formData.businessContact}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="formMessage">
        <Form.Label>Leave us a message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter your message"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

FormWithWebhook.propTypes = {
  onFormSubmit: PropTypes.func.isRequired
};

export default FormWithWebhook;
