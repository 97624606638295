// ProjectCards.js

import React from "react";
import Card from "react-bootstrap/esm/Card";
import Button from "react-bootstrap/esm/Button";
import { MdExitToApp } from "react-icons/md";
import { Link } from "react-router-dom";


function ProjectCard(props) {
  const isVideo = props.imgPath.endsWith(".mp4");

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Card className={`project-card-view`} onClick={handleClick}>
      {isVideo ? (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          {/* Video container matches the size of the poster */}
          <video
            poster={props.poster}
            controls
            controlsList="nodownload"
            preload="metadata"
            loop
            playbackRate="1.695"
            style={{ width: "100%", height: "100%" }}
          >
            <source src={props.imgPath} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        <Card.Img
          variant="top"
          src={props.imgPath}
          alt="card-img"
          style={{
            width: "100%",
            height: "auto",
            maxHeight: props.isAgent ? "700px" : "414px",
            cursor: "pointer",
          }}
        />
      )}
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "center" }}>
          {props.description}
        </Card.Text>
        {!props.isBlog && props.link && (
          <Button
            as={Link}
            to={props.link}
            variant="primary"
            style={{ marginLeft: "10px" }}
          >
            <MdExitToApp />  {"Explore"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default ProjectCard;
