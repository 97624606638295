import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import one from "../Assets/OnBoard/1.svg";
import Particle from "./Particle";

const Login = ({ handleLogin, handleSignUp, signInWithGoogle }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  // Step 1: Add state for error message
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isLogin) {
        await handleLogin(email, password);
      } else {
        await handleSignUp(email, password);
      }
      // Reset error message on successful login/signup
      setErrorMessage("");
    } catch (error) {
      // Step 2: Update error state on catching errors
      setErrorMessage(error.message || "An error occurred. Please try again.");
    }
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setEmail("");
    setPassword("");
    // Reset error message when toggling form
    setErrorMessage("");
  };

  return (
    <Container fluid className="login-container">
      <Particle />
      <Row className="login-items">
        <Col lg={6} className="login-content">
          <div className="login-form-container">
            <div className="login-form-header">
              <h1 className="login-title">{isLogin ? "Login" : "Sign Up"}</h1>
              <p className="login-instruction">
                Enter your email below to {isLogin ? "login" : "sign up"} to
                your account
              </p>
            </div>
            <form onSubmit={handleSubmit} className="login-form">
              <div className="login-form-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  placeholder="m@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="login-form-group">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <a href="/forgot-password" className="forgot-password-link">
                  Forgot your password?
                </a>
              </div>
              {/* Step 3: Display error message in UI */}
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              <button type="submit" className="login-button">
                {isLogin ? "Login" : "Sign Up"}
              </button>
              <button
                type="button"
                onClick={signInWithGoogle}
                className="google-login-button"
              >
                Sign In with Google
              </button>
            </form>
            <div className="login-footer">
              {isLogin
                ? "Don't have an account? "
                : "Already have an account? "}
              <button
                style={{
                  background: "none",
                  color: "#0d6efd",
                  border: "none",
                  padding: "0",
                  font: "inherit",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                type="button"
                className="login-toggle"
              >
                <span onClick={toggleForm} className="login-toggle">
                  <u>{isLogin ? "Sign up" : "Login"}</u>
                </span>
              </button>
            </div>
          </div>
        </Col>
        <Col lg={6} className="login-image">
          <img src={one} alt="Images" className="image login-image-size" />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;