const firebaseConfig = {
    apiKey: "AIzaSyCNY8c0uet-nM9NNn_z-0r6u-zgpHREYYQ",
    authDomain: "lionai-1b5ee.firebaseapp.com",
    projectId: "lionai-1b5ee",
    storageBucket: "lionai-1b5ee.appspot.com",
    messagingSenderId: "264603715080",
    appId: "1:264603715080:web:b9ed59bfbb166da52df3bd",
    measurementId: "G-ZJCLZQHSDG"
  };

  export { firebaseConfig };