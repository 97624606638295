import { useRef, useEffect, useState, useCallback } from "react";

// Async utility function to load the web component
const loadWebComponent = async () => {
  await import("../../lion-ai-by-vsm-embed/dist/web");
};

// FullPageChat component
const FullPageChat = ({ style, className, ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    loadWebComponent();
  }, []);

  useEffect(() => {
    if (ref.current) {
      Object.assign(ref.current, props);
    }
  }, [props]);

  return <lionai-fullchatbot ref={ref} style={style} class={className} />;
};

// BubbleChat component
const BubbleChat = (props) => {
  const ref = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    loadWebComponent().then(() => setIsLoaded(true));
    return () => {
      ref.current?.remove();
    };
  }, []);

  const appendChatBot = useCallback(() => {
    const chatbotElement = document.createElement("lionai-chatbot");
    ref.current = chatbotElement;
    updateProps(chatbotElement, props);
    document.body.append(chatbotElement);
  }, [props]);

  useEffect(() => {
    if (isLoaded) {
      if (!ref.current) {
        appendChatBot();
      }
      updateProps(ref.current, props);
    }
  }, [appendChatBot, isLoaded, props]);

  const updateProps = (element, newProps) => {
    Object.assign(element, newProps);
  };

  return null;
};

export { BubbleChat, FullPageChat };
