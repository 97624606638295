import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Img5 from "../../Assets/OnBoard/1.svg";
import Img6 from "../../Assets/OnBoard/2.png";
import Img7 from "../../Assets/OnBoard/3.svg";
import Img8 from "../../Assets/OnBoard/4.svg";
import Img9 from "../../Assets/Brand/Landscape-white.png";
import Tilt from "react-parallax-tilt";
import {
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaFacebookMessenger,
  FaTelegramPlane,
  FaDiscord
} from "react-icons/fa";
import { FaViber } from "react-icons/fa6";
import { MdWebhook } from "react-icons/md";
import { Card } from "react-bootstrap";
import ContactUs from "./ContactUs";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <ContactUs />
        <Row>
          <Col md={12} className="onboard-description">
            <h1 style={{ fontSize: "2.7rem", marginBottom: "50px" }}>
              Conversational AI: <b className="purple">The Future</b> of
              Customer Engagement
            </h1>
            <p>
              Introducing <b className="purple">LionAi</b>, the premier AI
              Automation Agency based in <b className="purple">Sri Lanka</b>. We
              specialize in providing businesses with cutting-edge
              <b className="purple"> conversational AI</b> solutions to 
              <b className="purple"> enhance customer engagement</b> and
              streamline operations. With LionAi, you can effortlessly automate
              <b className="purple"> customer support</b>,
              <b className="purple"> lead generation</b>, and
              <b className="purple"> sales</b> processes, saving valuable time
              {/* and resources. Our advanced chatbot technology is<b className="purple"> designed to understand and respond
              to customer queries with precision</b>, ensuring seamless interactions and increased customer
              satisfaction. Join the <b className="purple">LionAi revolution </b> today and 
              unlock the <b className="purple">power of AI</b> to transform your business. */}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={Img5} alt="Empowering Businesses with Intelligent Automation - LionAi" className="img-fluid" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-section">
            <h1 style={{ fontSize: "2.7rem", marginBottom: "50px" }}>
              Empowering Businesses with <strong className="purple">Intelligent</strong> Automation
            </h1>
            <p>
              Unlock your business's <b className="purple">full potential</b> with <b className="purple">LionAi</b>. Harnessing cutting-edge AI technology,
              we deliver seamless <b className="purple">intelligent automation</b> solutions,
              streamlining processes,
              and driving <b className="purple">unparalleled efficiency</b>.
              Revolutionize your operations today, Empower your business with the transformative power of intelligent automation.
            </p>
            {/* <ContactUs /> */}
          </Col>
        </Row>
        <Row>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img src={Img6} alt="Unlock the Power of Generative AI - LionAi" className="img-fluid" />
            </Tilt>
          </Col>
          <Col md={12} className="home-about-section">
            <h1 style={{ fontSize: "2.7rem", marginBottom: "50px" }}>
              Unlock the Power of<strong className="purple"> Generative AI</strong> 
            </h1>
            <p>
              Become an <b className="purple">Industry leader</b> with embracing the cutting-edge LLM (Language Model) technologies to drive growth, 
              <b className="purple"> enhance customer experience</b>, and stay ahead of the competition.
            </p>
            {/* <ContactUs /> */}
          </Col>
        </Row>
        <Row>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img src={Img7} alt="Fast and Personalized responses to your Customers - LionAi" className="img-fluid" />
            </Tilt>
          </Col>
          <Col md={12} className="home-about-section">
            <h1 style={{ fontSize: "2.7rem", marginBottom: "50px"}}>
              Fast and<strong className="purple"> Personalized</strong> responses to your Customers 
            </h1>
            <p>
              Instead of keeping your valuable <b className="purple">customers waiting in line</b> to talk to your support staff, provide <b className="purple">accurate</b> information,
              make the right <b className="purple">recommendations</b>, provide help, <b className="purple">whenever the customer requests</b>.
              Become an <b className="purple">Industry leader</b> with embracing the cutting-edge LLM (Language Model) technologies to drive growth, 
              <b className="purple"> enhance customer experience</b>, and stay ahead of the competition.
            </p>
            {/* <ContactUs /> */}
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-section">
            <h1 style={{ fontSize: "2.7rem", marginBottom: "50px" }}>
              You are<strong className="purple"> Always In Control</strong>, as it should be 
            </h1>
            <p>
              Take charge with our cutting-edge chatbot management platform. Enjoy the following features:
            </p>
            <ul style={{marginTop: "50px"}}>
              <li style={{ listStyleType: "circle", marginBottom: "10px" }}>Pause the AI and seamlessly transition to human support.</li>
              <li style={{ listStyleType: "circle", marginBottom: "10px" }}>Easily hand over conversations back to the AI.</li>
              <li style={{ listStyleType: "circle", marginBottom: "10px" }}>Monitor all deployed channels in real-time.</li>
              <li style={{ listStyleType: "circle" }}>Engage in live chat for instant communication.</li>
            </ul>
            {/* <ContactUs /> */}
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={Img8} alt="You are Always In Control, as it should be - LionAi" className="img-fluid" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="quote-card-view">
            <h1 style={{ fontSize: "2.6rem", marginBottom: "50px" }}>
              Seamlessly <strong className="purple">Integrate </strong>with
            </h1>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
          <Col xs={4} md={2} className="tech-icons" techname="WhatsApp">
            <FaWhatsapp />
          </Col>
          <Col xs={4} md={2} className="tech-icons" techname="Facebook">
            <FaFacebook />
          </Col>
          <Col xs={4} md={2} className="tech-icons" techname="Instagram">
            <FaInstagram />
          </Col>
          <Col xs={4} md={2} className="tech-icons" techname="FB Messenger">
            <FaFacebookMessenger />
          </Col>
          <Col xs={4} md={2} className="tech-icons" techname="Telegram">
            <FaTelegramPlane />
          </Col>
          <Col xs={4} md={2} className="tech-icons" techname="Viber">
            <FaViber />
          </Col>
          <Col xs={4} md={2} className="tech-icons" techname="Discord">
            <FaDiscord />
          </Col>
          <Col xs={4} md={2} className="tech-icons" techname="Webhook">
            <MdWebhook />
          </Col>
        </Row>
        <Card className="quote-card-view">
          <Card.Body>
            <blockquote className="blockquote mb-0">
              <p style={{ color: "lightcyan" }}>
                We provide custom integrations into <strong className="purple"> Any Platform</strong> you need.
              </p>
              <p style={{ color: "lightcyan" }}>
                Please <span className="purple">Contact Us</span> for a <span className="purple">Customized Pricing</span> strategy, based on your <span className="purple">Requirements</span>.
              </p>
              <footer className="blockquote-footer">Admin</footer>
            </blockquote>
          </Card.Body>
        </Card>
        <Row className="myAvtar">
          <Col md={8} className="myAvtar" style={{marginTop: "-100px", marginBottom: "-100px"}} >
            <Tilt>
              <img src={Img9} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Connect With Us</h1>
            <p>
              Contact us <span className="purple">Today!</span>
            </p>
            <ContactUs />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;