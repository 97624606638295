import React, { useState } from "react";
import { FullPageChat } from "./lion-ai-by-vsm-embed-react/dist";
import Particle from "../Particle";
import { IoLockOpen } from "react-icons/io5";
import { Button } from 'reactstrap';
import { getAuth, signOut } from "firebase/auth";

const Agent02 = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);

    const handleOpenChat = () => {
        setIsChatOpen(true);
    };

    const handleCloseChat = () => {
        setIsChatOpen(false);
    };

    const auth = getAuth();
    const user = auth.currentUser;
    const handleLogout = async () => {
      try {
        await signOut(auth);
        // User is signed out
      } catch (error) {
        console.error(error);
      }
    };

    return (
        <div className="agent-view">
          <div style={{ flex: 1 }}>
            <Particle />
            <div className="agent-view1">
                {user && (
                    <div style={{ textAlign: "center" }}>
                        <h3 style={{ color: "white" }}>Logged in as: {user.email}</h3>
                        <Button type="button" color="danger" onClick={handleLogout}>Logout</Button>
                    </div>
                )}
                {isChatOpen ? (
                    <FullPageChat
                        chatflowid="85455730-e224-4e05-9110-4be4318541d5"
                        apiHost="https://api.lionai.pro"
                        theme={{
                            chatWindow: {
                                showTitle: true,
                                title: "AromaAI",
                                titleAvatarSrc: 'https://cdn.sanity.io/images/1dmsbh4j/production/dfcacf1433c4ad3db259ca92d00221279db34f13-1081x1081.png',
                                welcomeMessage: `Welcome to AromaAI 🦁🧡`,
                                backgroundColor: "#082d588e",
                                minHeight: "30vh",
                                maxHeight: "calc(100vh - 150px)",
                                width: "calc(75vw - 50px)",
                                fontSize: 16,
                                poweredByTextColor: "#ffffff",
                                showFloatingChatButton: false,
                                botMessage: {
                                    backgroundColor: "#22426685",
                                    textColor: "#ffffff",
                                    showAvatar: true,
                                    avatarSrc: 'https://cdn.sanity.io/images/1dmsbh4j/production/c206ea23de794bbe5436603757d35b653f0ac2a0-1024x1024.jpg',
                                },
                                userMessage: {
                                    backgroundColor: "#22426685",
                                    textColor: "#ffffff",
                                    showAvatar: true,
                                    avatarSrc: 'https://cdn.sanity.io/images/1dmsbh4j/production/7bd25e01e05b20e51f2276ed2d35f858180cd98f-1024x1024.png',
                                },
                                textInput: {
                                    placeholder: "Type your question:",
                                    backgroundColor: "#ffffff",
                                    textColor: "#000000",
                                    sendButtonColor: "#051C36",
                                }
                            }
                        }}
                        onClose={handleCloseChat}
                    />
                ) : (
                    <div style={{ textAlign: "center" }}>
                        <h1 style={{ fontSize: "2.6rem", marginBottom: "50px", color: "white" }}>
                            Store |<strong className="purple"> AromaAI </strong>|  v1.7
                        </h1>
                        <p style={{ color: "white" }} >For: <strong style={{ color: "lightcyan" }} > AromaBlissCeylon</strong></p>
                        <p style={{ color: "white" }} >Supported: <strong style={{ color: "lightcyan" }} > English, Sinhala, Singlish</strong></p>
                        <p style={{ color: "white" }} >Product recommendation Accuracy: <strong style={{ color: "lightcyan" }} > +96.2%</strong></p>
                        <p style={{ color: "white" }} >RAG (Retrieval Augmented Generation) Accuracy: <strong style={{ color: "lightcyan" }} > +96.2%</strong></p>
                        {/* <a target="_blank" rel="noreferrer" href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTB4z6Dv707QvxrHeZvcTVfwAeAdDtJ1tqRohIX97tj1Rbj4PPxy5t5Iw4ETWvjjheb5_Sq9iaZ7vYN/pubhtml?gid=0&single=true">
                          Spreadsheet
                        </a>
                        <p style={{ color: "ffffff" }} >Test below</p> */}
                        <Button type="button" color="primary" onClick={handleOpenChat}><IoLockOpen />Open Chat</Button>
                    </div>
                )}
            </div>
        </div>
        </div>
    );
};

export default Agent02;
