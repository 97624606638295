import React, { useEffect } from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const loadLionAiScript = () => {
  const lionAiScript = document.createElement('script');
  lionAiScript.type = 'module';
  lionAiScript.src = "https://cdn.jsdelivr.net/gh/VSM97/lion-ai-by-vsm-embed@Roar/dist/web.js";
  document.body.appendChild(lionAiScript);
};

const RootComponent = () => {
  useEffect(() => {
    loadLionAiScript();
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<RootComponent />);

reportWebVitals();
