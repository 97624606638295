import React, { useState, useEffect } from "react";
import { FullPageChat } from "./lion-ai-by-vsm-embed-react/dist";
import Particle from "../Particle";
import { IoLockOpen } from "react-icons/io5";
import { Button } from 'reactstrap';
import { getAuth, signOut } from "firebase/auth";

const Agent01 = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);

    useEffect(() => {
        // Scroll to top when component mounts
        window.scrollTo(0, 0);
    }, []);

    const handleOpenChat = () => {
        setIsChatOpen(true);
    };

    const handleCloseChat = () => {
        setIsChatOpen(false);
    };

    const auth = getAuth();
    const user = auth.currentUser;
    const handleLogout = async () => {
      try {
        await signOut(auth);
        // User is signed out
      } catch (error) {
        console.error(error);
      }
    };

    return (
        <div className="agent-view">
          <div style={{ flex: 1 }}>
            <Particle />
            <div className="agent-view1">
                {user && (
                    <div style={{ textAlign: "center" }}>
                        <h3 style={{ color: "white" }}>Logged in as: {user.email}</h3>
                        <Button type="button" color="danger" onClick={handleLogout}>Logout</Button>
                    </div>
                )}
                {isChatOpen ? (
                    <FullPageChat
                            chatflowid="930c8bad-22b7-41d4-b687-22e6cce67c69"
                            apiHost="https://api.lionai.pro"
                            theme={{
                                chatWindow: {
                                    showTitle: true,
                                    title: "kaptain v0.1",
                                    titleAvatarSrc: 'https://cdn.sanity.io/images/1dmsbh4j/production/c894c81dd30433a7577c0c830dbd1f0bafa1b50b-62x70.webp',
                                    welcomeMessage: `Dev - alpha`,
                                    backgroundColor: "#082d588e",
                                    minHeight: "30vh",
                                    maxHeight: "calc(100vh - 150px)",
                                    width: "calc(75vw - 50px)",
                                    fontSize: 16,
                                    poweredByTextColor: "#ffffff",
                                    showFloatingChatButton: false,
                                    botMessage: {
                                        backgroundColor: "#22426685",
                                        textColor: "#ffffff",
                                        showAvatar: true,
                                        avatarSrc: 'https://cdn.sanity.io/images/1dmsbh4j/production/c206ea23de794bbe5436603757d35b653f0ac2a0-1024x1024.jpg',
                                    },
                                    userMessage: {
                                        backgroundColor: "#22426685",
                                        textColor: "#ffffff",
                                        showAvatar: true,
                                        avatarSrc: 'https://cdn.sanity.io/images/1dmsbh4j/production/7bd25e01e05b20e51f2276ed2d35f858180cd98f-1024x1024.png',
                                    },
                                    textInput: {
                                        placeholder: "Type your question:",
                                        backgroundColor: "#ffffff",
                                        textColor: "#000000",
                                        sendButtonColor: "#051C36",
                                    }
                                }
                            }}
                            onClose={handleCloseChat} />
                ) : (
                    <div style={{ textAlign: "center" }}>
                        <h1 style={{ fontSize: "2.6rem", marginBottom: "50px", color: "white" }}>
                            Team |<strong className="purple"> Dev - Team </strong>|  v0.1
                        </h1>
                        <p style={{ color: "white" }} >Alpha testing dev team</p>
                        <p style={{ color: "white" }} >Env: <strong style={{ color: "lightcyan" }} > NodeJS, ExpressJS, NextJS, ReactJS</strong></p>
                        <Button type="button" color="primary" onClick={handleOpenChat}><IoLockOpen />Open Chat</Button>
                    </div>
                )}
            </div>
        </div>
        </div>
    );
};

export default Agent01;