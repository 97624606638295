import React, { useState } from "react";
import { FullPageChat } from "./lion-ai-by-vsm-embed-react/dist";
import Particle from "../Particle";
import { IoLockOpen } from "react-icons/io5";
import { Button } from 'reactstrap';
import { getAuth, signOut } from "firebase/auth";

const Agent04 = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);

    const handleOpenChat = () => {
        setIsChatOpen(true);
    };

    const handleCloseChat = () => {
        setIsChatOpen(false);
    };

    const auth = getAuth();
    const user = auth.currentUser;
    const handleLogout = async () => {
      try {
        await signOut(auth);
        // User is signed out
      } catch (error) {
        console.error(error);
      }
    };

    return (
        <div className="agent-view">
          <div style={{ flex: 1 }}>
            <Particle />
            <div className="agent-view1">
                {user && (
                    <div style={{ textAlign: "center" }}>
                        <h3 style={{ color: "white" }}>Logged in as: {user.email}</h3>
                        <Button type="button" color="danger" onClick={handleLogout}>Logout</Button>
                    </div>
                )}
                {isChatOpen ? (
                    <FullPageChat
                        chatflowid="fed5863a-27af-4f4f-8d42-950729930431"
                        apiHost="https://api.lionai.pro"
                        chatflowConfig={{
                            enableChatHistory: true,
                            enableVoiceInput: true,
                            enableSpeechSynthesis: true,
                            enableSpeechSynthesisVoice: "Google UK English",
                            enableFileUpload: true,
                        }}
                        observersConfig={{
                            // (optional) Allows you to execute code in parent based upon signal observations within the chatbot.
                            // The userinput field submitted to bot ("" when reset by bot)
                            observeUserInput: (userInput) => {
                                console.log("observeUserInput", userInput);
                            },
                            observeMessages: (messages) => {
                                console.log("observeMessages", messages);
                            },
                            observeLoading: (loading) => {
                                console.log("observeLoading", loading);
                            }
                        }}
                        theme={{
                            chatWindow: {
                                showTitle: false,
                                title: "Test",
                                titleAvatarSrc: 'https://cdn.sanity.io/images/1dmsbh4j/production/dfcacf1433c4ad3db259ca92d00221279db34f13-1081x1081.png',
                                welcomeMessage: `Orca-Mini 🦁🧡`,
                                backgroundColor: "#082d588e",
                                minHeight: "30vh",
                                maxHeight: "calc(100vh - 150px)",
                                width: "calc(75vw - 50px)",
                                fontSize: 16,
                                poweredByTextColor: "#ffffff",
                                showFloatingChatButton: false,
                                botMessage: {
                                    backgroundColor: "#22426685",
                                    textColor: "#ffffff",
                                    showAvatar: true,
                                    avatarSrc: 'https://cdn.sanity.io/images/1dmsbh4j/production/c206ea23de794bbe5436603757d35b653f0ac2a0-1024x1024.jpg',
                                },
                                userMessage: {
                                    backgroundColor: "#22426685",
                                    textColor: "#ffffff",
                                    showAvatar: true,
                                    avatarSrc: 'https://cdn.sanity.io/images/1dmsbh4j/production/7bd25e01e05b20e51f2276ed2d35f858180cd98f-1024x1024.png',
                                },
                                textInput: {
                                    placeholder: "Type your question:",
                                    backgroundColor: "#ffffff",
                                    textColor: "#000000",
                                    sendButtonColor: "#051C36",
                                }
                            }
                        }}
                        onClose={handleCloseChat}
                    />
                ) : (
                    <div style={{ textAlign: "center" }}>
                        <h1 style={{ fontSize: "2.6rem", marginBottom: "50px", color: "white" }}>
                            LLM |<strong className="purple"> Llama 3 </strong>| Agentic
                        </h1>
                        <p style={{ color: "white" }} >Inference Test<strong style={{ color: "lightcyan" }} > Meta/ Llama3 </strong></p>
                        <p style={{ color: "white" }} >Beta testing.<strong style={{ color: "lightcyan" }} > Low parameter LLM test</strong></p>
                        <p style={{ color: "white" }} >Rate Limited: <strong style={{ color: "lightcyan" }} > LLM Test</strong></p>
                        {/* <p style={{ color: "white" }} >RAG (Retrieval Augmented Generation) Accuracy: <strong style={{ color: "lightcyan" }} > Null</strong></p> */}
                        <Button type="button" color="primary" onClick={handleOpenChat}><IoLockOpen />Open Chat</Button>
                    </div>
                )}
            </div>
        </div>
        </div>
    );
};

export default Agent04;
