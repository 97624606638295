// Features.js

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import SmartConverse from "../../Assets/Projects/SmartConverse.jpeg";
import FreeFlow from "../../Assets/Projects/FreeFlow.jpeg";
import SinglishConnect from "../../Assets/Projects/SinglishConnect.jpeg";
import one from "../../Assets/Projects/1.mp4";
import two from "../../Assets/Projects/2.mp4";
import three from "../../Assets/Projects/3.mp4";

function Features() {
  return (
    <Container fluid className="project-section" style={{ textAlign: "center"}}>
      <Container>
        <Particle />
        <h1 className="project-heading">
          Our <strong className="purple">Features </strong>
        </h1>
        <p style={{ color: "white", fontSize: "20px"  }}>
          Checkout our unique features.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "5px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={one}
              poster={SmartConverse}
              isBlog={false}
              title="SmartConverse™"
              description="AI-driven chatbot solution for personalized experiences, enhancing operational efficiency and customer loyalty."
              ghLink="https://lionai.pro/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={three}
              poster={FreeFlow}
              isBlog={false}
              title="FreeFlow™"
              description="Conversational AI enabling dynamic interaction, understanding user intents with unmatched precision."
              ghLink="https://lionai.pro/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={two}
              poster={SinglishConnect}
              isBlog={false}
              title="SinglishConnect™"
              description="Multilingual finesse for seamless communication, breaking down linguistic barriers effectively and promptly."
              ghLink="https://lionai.pro/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Features;
