import React, { useState } from "react";
import Navbar from "react-bootstrap/esm/Navbar";
import Nav from "react-bootstrap/esm/Nav";
import Container from "react-bootstrap/esm/Container";
import logo from "../Assets/Brand/Whiteicon.png";
import { Link } from "react-router-dom";
import { ImBlog } from "react-icons/im";
import { AiOutlineHome, AiOutlineFundProjectionScreen, AiOutlineAim, AiOutlineCompass } from "react-icons/ai";
import FormWithWebhook from "./Home/FormWithWebhook";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [showForm, updateShowForm] = useState(false);
  // const [ setOpen] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  // const scrollToSection = (sectionId) => {
  //   const sectionElement = document.getElementById(sectionId);
  //   const offset = 128;
  //   if (sectionElement) {
  //     const targetScroll = sectionElement.offsetTop - offset;
  //     sectionElement.scrollIntoView({ behavior: 'smooth' });
  //     window.scrollTo({
  //       top: targetScroll,
  //       behavior: 'smooth',
  //     });
  //     setOpen(false);
  //   }
  // };

  window.addEventListener("scroll", scrollHandler);

  return (
    <>
      <Navbar
        expanded={expand}
        fixed="top"
        expand="md"
        className={navColour ? "sticky" : "navbar"}
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className="d-flex">
            <img src={logo} className="img-fluid logo" alt="brand" />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : "expanded");
            }}
          >
            <span></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" defaultActiveKey="#home">
              <Nav.Item>
                <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                  <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link as={Link} to="/Features" onClick={() => updateExpanded(false)}>
                  <AiOutlineFundProjectionScreen style={{ marginBottom: "2px" }} /> Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link as={Link} to="/Den" onClick={() => updateExpanded(false)}>
                  <AiOutlineAim style={{ marginBottom: "2px" }} /> Den
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link as={Link} to="/Landing" onClick={() => updateExpanded(false)}>
                  <AiOutlineCompass style={{ marginBottom: "2px" }} /> Landing
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/Blogs"
                  onClick={() => updateExpanded(false)}
                >
                  <ImBlog style={{ marginBottom: "2px" }} /> Blog
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link as={Link} to="/Landing" onClick={() => scrollToSection('features')}>
                  <ImBlog style={{ marginBottom: "2px" }} /> Features1
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/Landing" onClick={() => scrollToSection('Testimonials')}>
                  <ImBlog style={{ marginBottom: "2px" }} /> Testimonials
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/Landing" onClick={() => scrollToSection('Highlights')}>
                  <ImBlog style={{ marginBottom: "2px" }} /> Highlights
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/Landing" onClick={() => scrollToSection('Pricing')}>
                  <ImBlog style={{ marginBottom: "2px" }} /> Pricing
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/Landing" onClick={() => scrollToSection('FAQ')}>
                  <ImBlog style={{ marginBottom: "2px" }} /> FAQ
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {showForm && <FormWithWebhook onSubmit={() => updateShowForm(false)} />}
    </>
  );
}

export default NavBar;