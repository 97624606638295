import React, { useState, useEffect } from 'react';
import {  Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Preloader from './components/Pre';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import './style.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from './firebaseConfig';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Home from './components/Home/Home';
import Features from './components/Features/Features';
import Blogs from './components/Blog/Blogs';
import BlogPost from './components/Blog/BlogPost';
import SanityStudio from './components/Blog/SanityStudio';
import Login from './components/Login';
import Den from './components/Den/den';
import LandingPage from './components/Landing/LandingPage';

import Agent01 from './components/Agents/Agent01';
import Agent02 from './components/Agents/Agent02';
import Agent03 from './components/Agents/Agent03';
import Agent04 from './components/Agents/Agent04';
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

function App() {
  const [load] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        // User is signed in.
        setUser(authUser);
      } else {
        // User is signed out.
        setUser(null);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []); // Removed 'auth' from the dependency array

  const handleLogin = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSignUp = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error(error);
    }
  };

  const location = useLocation();

  // List of routes where the footer should be hidden
  const hideFooterRoutes = ["/agent01", "/agent02", "/agent03", "/agent04"];

  return (
    <div className="body-flex-container">
      <div className="content-wrapper">
          {load && <Preloader />}
          <Navbar user={user} />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path='/features' element={<Features />} />
            <Route path="/den" element={user ? <Den /> : <Navigate to="/login" state={{ from: '/den' }} />} />
            <Route path='/landing' element={<LandingPage />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/studio" element={<SanityStudio />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/agent01" element={user ? <Agent01 /> : <Navigate to="/login" state={{ from: '/agent01' }} />} />
            <Route path="/agent02" element={user ? <Agent02 /> : <Navigate to="/login" state={{ from: '/agent02' }} />} />
            <Route path="/agent03" element={user ? <Agent03 /> : <Navigate to="/login" state={{ from: '/agent03' }} />} />
            <Route path="/agent04" element={user ? <Agent04 /> : <Navigate to="/login" state={{ from: '/agent04' }} />} />
            <Route path="/login" element={user ? <Navigate to="/agent01" /> : <Login handleLogin={handleLogin} handleSignUp={handleSignUp} signInWithGoogle={signInWithGoogle} />} />
            <Route path="/login" element={user ? <Navigate to="/agent02" /> : <Login handleLogin={handleLogin} handleSignUp={handleSignUp} signInWithGoogle={signInWithGoogle} />} />
            <Route path="/login" element={user ? <Navigate to="/agent03" /> : <Login handleLogin={handleLogin} handleSignUp={handleSignUp} signInWithGoogle={signInWithGoogle} />} />
          </Routes>
      </div>
      {/* Conditionally render the footer based on the current route */}
      {!hideFooterRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
}

export default App;
